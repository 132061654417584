





































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import UiVideoLoop from '@/components/ui/VideoLoop.vue'

export default defineComponent({
  name: 'BlogHero',
  components: {
    UiVideoLoop,
  },
  setup() {
    const formatDate = (date: number, datetime = false) => {
      if (datetime) {
        return new Date(date).toISOString()
      }

      const formatter = new Intl.DateTimeFormat('fr-be', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      })

      const formatted = formatter.format(date)

      return formatted.split('/').join('.')
    }

    return {
      formatDate,
      ...useGetters(['content']),
    }
  },
})
