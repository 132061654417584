














import { defineComponent, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText.js'

import BlogHero from '@/components/blog/Hero.vue'
import HomePromo from '@/components/home/Promo.vue'

gsap.registerPlugin(SplitText)

export default defineComponent({
  name: 'BlogSingle',
  components: {
    BlogHero,
    HomePromo,
  },
  setup() {
    const { content } = useGetters(['content'])
    let promoLabel: string | null = null

    // Get promo label to add on hero
    // Filter flexible components to get cpts that can add anchors
    const anchorComponents = content.value.flexibleContent.filter(
      component =>
        component.component === 'highlightDuo' ||
        component.component === 'ctaPromo'
    )

    // Find the (first) one with an anchor
    const anchorComponent = anchorComponents.find(cta => cta.content.anchor)

    if (anchorComponent) {
      promoLabel = anchorComponent.content.anchor.label
    }

    onMounted(() => {
      const page = document.querySelector('.blog-single')
      const hero = page!.querySelector('.blog-hero')
      const bg = hero!.querySelector('.blog-hero__bg')
      const crumbs = hero!.querySelectorAll('.crumbs__item')
      const box = hero!.querySelector('.blog-hero__content')
      const title = hero!.querySelector('.blog-hero__content__title')
      const picture = hero!.querySelector('[class*="blog-hero__visual--"]')
      const details = hero!.querySelectorAll(
        '.blog-hero__content > *:not(.blog-hero__content__title)'
      )
      const anchor = document.querySelector('.blog-single__promo__cta')
      const content = document.querySelector('.blog-single .flexible')

      const splitTitle = new SplitText(title, { type: ['words'] })

      const tl = gsap.timeline({
        onComplete: () => {
          splitTitle.revert()
          gsap.set([hero, bg, crumbs, box, picture, details], {
            clearProps: 'all',
          })
        },
      })

      tl.set(page, { opacity: 1 }, 0)
        .set(splitTitle.words, {
          clipPath: 'inset(0% 0% 100% 0%)',
        })
        .add('start', 0.5)

      if (window.innerWidth < 960) {
        tl.set(bg, { bottom: '100%', height: '0%' }, 0)
          .set(picture, { opacity: 0 }, 0)
          .to(
            bg,
            { bottom: '0%', height: '100%', ease: 'power4.in', duration: 0.5 },
            'start'
          )
          .set(picture, { opacity: 1, ease: 'power4.out' })
          .to(bg, { height: '30%', ease: 'expo.out', duration: 1 })
      } else {
        tl.set(bg, { left: '100%', width: '0%' }, 0)
          .set(picture, { opacity: 0 }, 0)
          .to(
            bg,
            { left: '0%', width: '100%', ease: 'power4.in', duration: 0.5 },
            'start'
          )
          .set(picture, { opacity: 1, ease: 'power4.out' })
          .to(bg, { width: '50%', ease: 'expo.out', duration: 1 })
      }

      tl.add('box')
        .from(box, { y: 50, opacity: 0 }, 'box')
        .to(
          splitTitle.words,
          {
            clipPath: 'inset(0% 0% -30% 0%)',
            stagger: 0.05,
            duration: 0.75,
            ease: 'power4.out',
          },
          'box'
        )
        .from(
          splitTitle.words,
          {
            y: 50,
            stagger: 0.05,
            duration: 0.5,
            ease: 'power4.out',
          },
          'box'
        )
        .from(crumbs, { y: 20, opacity: 0, stagger: 0.05 }, 'box+=0.25')
        .from(
          details,
          { opacity: 0, duration: 0.75, ease: 'power4.out' },
          'box+=0.5'
        )

      if (anchor) {
        tl.from(anchor, { opacity: 0, duration: 0.5 }, 'box+=0.5')
      }

      tl.from(
        content,
        { opacity: 0, duration: 1.5, ease: 'power4.out' },
        'box+=0.75'
      )
    })

    return {
      content,
      promoLabel,
    }
  },
})
