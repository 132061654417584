


















import type { Picture } from '@/inc/types'
import { defineComponent, PropType, onMounted, ref } from '@vue/composition-api'

export interface VideoLoop {
  video: string
  picture: Picture
}

import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'VideoLoop',
  components: {
    UiPicture,
  },
  props: {
    content: {
      type: Object as PropType<VideoLoop>,
      required: true,
    },
    cover: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_props, { emit }) {
    const rootEl = ref<HTMLElement | null>(null)
    const videoEl = ref<HTMLVideoElement | null>(null)
    const showPicture = ref(true)

    // When video is loaded, hide picture and play loop
    const onVideoLoad = () => {
      videoEl.value?.play()
      showPicture.value = false
      emit('load')
    }

    onMounted(() => {
      // Wait for video load to hide fallback picture
      if (videoEl.value) {
        // Check if video is already loaded
        if (videoEl.value.readyState >= 3) {
          onVideoLoad()
        } else {
          videoEl.value.onloadeddata = () => {
            onVideoLoad()
          }

          videoEl.value.load()
        }
      }
    })

    return {
      rootEl,
      videoEl,
      showPicture,
    }
  },
})
